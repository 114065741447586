import React, { useCallback } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';

import { HeadlineView } from 'utilities/components/headline/component/view';
import { DoorView } from 'utilities/components/door/component/view';
import { ButtonsView } from 'utilities/components/buttons/component/view';
import { ButtonView } from 'utilities/components/button/component/view';

import { paths } from 'utilities/routes';

import './view.scss';
import { generateSlug } from 'utilities/helpers/generateSlug';
import { getMainDoorImage } from 'utilities/helpers/getMainDoorImage';

SwiperCore.use([Navigation]);

export const DoorsView = ({ doors }) => {
    const fillSlidesToPreventBlankSpaces = useCallback((doorArray) => {
        if (!doorArray) return undefined;
        const elementWidth = (typeof window) !== 'undefined' && document.querySelector('.doors__carousel .carousel__item')?.offsetWidth;
        const windowWidth = (typeof window) === 'undefined' ? 1920 : window.innerWidth;
        const neededElementsToWork = Math.ceil(windowWidth / (elementWidth || 260)) * 2;
        const amountOfCopies = Math.floor(neededElementsToWork / (doorArray.length || 1)) || 1;
        const clonedArray = Array.from({ length: amountOfCopies }, () => doorArray).flat();
        return clonedArray;
    }, [doors]);
    return (
        <section className="doors">
            <HeadlineView text="Podobne drzwi" />
            <Swiper
                className="doors__carousel"
                navigation={{
                    nextEl: '.doors--next',
                    prevEl: '.doors--previous'
                }}
                slidesPerView="auto"
                centeredSlides
                loop
                loopedSlides={ doors.length * 4 }
                loopFillGroupWithBlank
            >
                {
                    fillSlidesToPreventBlankSpaces(doors)?.map((slide, index) => (
                        <SwiperSlide className="carousel__item" key={ index }>
                            <DoorView
                                id={ slide.alternative_id }
                                collection={ slide.lines.name }
                                image={{ main_model: getMainDoorImage(slide) }}
                                headline={ slide.name }
                                price={ slide.price }
                                bestseller={ slide.bestseller }
                            />
                        </SwiperSlide>
                    ))
                }
                <ButtonsView name="doors" />
            </Swiper>
            <ButtonView text="Zobacz więcej" link={ `${paths.produkty}/${generateSlug(doors[0].lines.brands.type)}` } />
        </section>
    );
};

DoorsView.propTypes = {
    doors: PropTypes.instanceOf(Array).isRequired
};