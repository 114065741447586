import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { Navigation } from 'utilities/components/navigation/component';
import { Details } from 'features/door/details/component';
import { Doors } from 'utilities/components/doors';
import { Footer } from 'utilities/components/footer/component';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import SEO from 'utilities/components/seo';
import { paths, routes } from 'utilities/routes';

import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { generateSlug } from 'utilities/helpers/generateSlug';
import { getMainDoorImage } from 'utilities/helpers/getMainDoorImage';

export const query = graphql`
    query($id: Int) {
        allDoors(filter: { is_active: { eq: 1 }, alternative_id: { eq: $id } }) {
            nodes {
                accessories {
                    alternative_id
                    image
                    name
                    price
                }
                seo_description
                seo_meta_description
                seo_meta_title
                bestseller
                alternative_id
                variants {
                    main
                    gallery_color_id
                    gallery_color
                    gallery_variant
                    main_model
                    second_model
                    third_model
                    fourth_model
                    fifth_model
                    line_variant {
                        name
                        trim {
                            image
                        }
                    }
                    color {
                        image
                        name
                        code
                        color_type
                        color_filter
                        color_filter_name
                        cms_name
                        alternative_id
                        show_color_type
                    }
                }
                custom_variants {
                    main
                    gallery_color_id
                    gallery_color
                    gallery_variant
                    main_model
                    second_model
                    third_model
                    fourth_model
                    fifth_model
                    line_variant {
                        name
                        trim {
                            image
                        }
                    }
                    color {
                        image
                        name
                        code
                        color_type
                        color_filter
                        color_filter_name
                        cms_name
                        alternative_id
                        show_color_type
                    }
                }
                frame_list {
                    name
                    cms_name
                    column1
                    column2
                    column3
                    table
                }
                name
                lines {
                    benefits {
                        content
                    }
                    accessories {
                        alternative_id
                        image
                        name
                        price
                    }
                    acoustics {
                        name
                    }
                    name
                    alternative_id
                    accessories_text
                    accessories_text_link
                    accessories_text_name
                    brands {
                        type
                        name
                    }
                    description
                    color {
                        alternative_id
                        name
                        code
                        image
                        show_color_type
                    }
                    finishing {
                        name
                        image
                    }
                    stone_text
                    stone_text_name
                    stone_text_link
                    stones {
                        alternative_id
                        name
                        image
                    }
                    door_completion
                    construction_text
                    construction_text_link
                    construction_text_name
                    varnishing_text
                    glass_text
                    glass_text_link
                    glass_text_name
                    glass_colors {
                        alternative_id
                        name
                        image
                    }
                    standard_set_list {
                        desc
                    }
                    width_standard
                    width_max
                    height_standard
                    height_max
                    frame_options_set {
                        desc
                    }
                    decoration_panel_text
                    decoration_panel_text_link
                    decoration_panel_text_name
                    decoration_panels {
                        name
                        image
                    }
                    addons_text
                    addons_text_link
                    addons_text_name
                    addons {
                        name
                        image
                    }
                    fitting_set_list {
                        desc
                    }
                    other_options_text
                    other_options_text_link
                    other_options_text_name
                    finishing_colors {
                        colors {
                            alternative_id
                            cms_name
                            code
                            color_filter
                            color_type
                            image
                            name
                            show_color_type
                        }
                        name
                        image
                    }
                }
                price
                price_type
                construction_text
                construction_text_link
                construction_text_name
                varnishing_text
                varnishing_text_link
                varnishing_text_name
                other_options_text
                other_options_text_link
                other_options_text_name
                accessories_text
                accessories_text_link
                accessories_text_name
                standard_set_list {
                    desc
                }
                standard_set_text_name
                standard_set_text_link
                width_standard
                width_max
                height_standard
                height_max
                dimensions_text_name
                dimensions_text_link
                fitting_set_list {
                    desc
                }
                glass_text
                glass_text_name
                glass_text_link
                stone_text
                stone_text_name
                stone_text_link
                addons_text
                addons_text_link
                addons_text_name
                decoration_panel_text
                decoration_panel_text_link
                decoration_panel_text_name
                glass_colors {
                    name
                    image
                }
                stones {
                    image
                    name
                }
                addons {
                    image
                    name
                }
                decoration_panels {
                    image
                    name
                }
                benefits {
                    content
                }
            }
        }
        frames {
            data {
                brandId
                brandName
                lineId
                lineName
                tabels {
                    frameTypeId
                    frameTypeName
                    rows {
                        column1
                        column2
                        column3
                    }
                    columns {
                        column1
                        column2
                        column3
                    }
                }
            }
        }
        allArticles {
            nodes {
                slug
                title
                subtype
                reading_time
                image_main
                alternative_id
            }
        }
    }
`;

const Door = ({ data }) => {
    const productTypeBreadcrubsObject = {
        path: `${routes.produkty.path}/${generateSlug(data?.allDoors?.nodes[0]?.lines?.brands?.type)}`,
        name: data?.allDoors?.nodes[0]?.lines?.brands?.type
    };

    const productBreadcrubsObject = {
        path: `${routes.produkt.path}/${data?.allDoors?.nodes[0]?.alternative_id}`,
        name: data?.allDoors?.nodes[0]?.name
    };

    const breadcrumbs = [routes.home, productTypeBreadcrubsObject, productBreadcrubsObject];

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };

    return (
        <main className="product">
            <SEO
                title={
                    data?.allDoors?.nodes[0]?.seo_meta_title
                    || `${data?.allDoors?.nodes[0]?.lines?.brands?.type} ${data?.allDoors?.nodes[0]?.name} | Barański Drzwi`
                }
                description={
                    data?.allDoors?.nodes[0]?.seo_meta_description
                    || `Wejdź na stronę i poznaj naszą ofertę. Poznaj markę Barański i dowiedz się więcej o produkcie ${data?.allDoors?.nodes[0]?.name} ${data?.allDoors?.nodes[0]?.lines?.name} ${data?.allDoors?.nodes[0]?.lines?.brands?.type}`
                }
                image={ `${process.env.GATSBY_URL}/${getMainDoorImage(data?.allDoors?.nodes[0])}` }
                alt={ data?.allDoors?.nodes[0]?.name }
                canonicalLink={ setCanonicalLink() }
            />
            <Helmet>
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "Product",
                            "name": "${data?.allDoors?.nodes[0]?.name}",
                            "description": "${data?.allDoors?.nodes[0]?.lines?.description}",
                            "brand": "${data?.allDoors?.nodes[0]?.lines?.brands?.name}",
                            "image": "${process.env.GATSBY_URL}/${getMainDoorImage(data?.allDoors?.nodes[0])}",
                            "offers": {
                                "@type": "Offer",
                                "url": "https://www.baranskidrzwi.pl${paths.produkt}/${
                        data?.allDoors?.nodes[0]?.alternative_id
        }",
                                "priceCurrency": "PLN",
                                "price": "${data?.allDoors?.nodes[0]?.price}",
                                "availability": "https://schema.org/InStock",
                                "itemCondition": "https://schema.org/NewCondition"
                            }
                        }
                    `}
                </script>
            </Helmet>
            <Navigation />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <Details data={ data?.allDoors?.nodes[0] } frames={ data?.frames?.data } articles={ data?.allArticles?.nodes } />
            <Doors line={ data?.allDoors?.nodes?.[0]?.lines } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default Door;

Door.propTypes = {
    data: PropTypes.string.isRequired
};
