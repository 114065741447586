/* eslint-disable dot-notation */
import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { Details } from 'utilities/components/product/details/component';
import { Property } from 'utilities/components/product/property/component';
import { ListView } from 'utilities/components/product/list/component/view';
import { OptionsView } from 'utilities/components/product/options/component/view';
import { Field } from 'utilities/components/filters/field/component';
import DoorFrames from 'utilities/components/product/door-frames/component/idnex';
import { Img } from 'utilities/components/img/component';
import { doorTypes } from 'utilities/data/doors';
import picking from 'assets/images/product/picking.svg';
import notes from 'assets/images/product/notes--bright.svg';
import { PostView } from 'features/blog/post/view';
import { Swiper, SwiperSlide } from 'swiper/react';
import { routes } from 'utilities/routes';
import { ButtonsView } from 'utilities/components/buttons/component/view';

export const DetailsView = ({
    product,
    frames,
    finishes,
    properties,
    articles,
    setProperty
}) => {
    const doorDimensions = {
        widths: () => {
            const productWidth = product?.width_standard || product?.lines?.width_standard;
            const productWidthMax = product?.width_max || product?.lines?.width_max;

            if (productWidth && productWidthMax) {
                return [...productWidth?.split(',').map((width) => width), ...productWidthMax?.split(',').map((width) => width)];
            }
            if (productWidth && !productWidthMax) {
                return [...productWidth?.split(',').map((width) => width)];
            }
            return [...productWidthMax?.split(',').map((width) => width)];
        },
        heights: () => {
            const productHeight = product?.height_standard || product?.lines?.height_standard;
            const productHeightMax = product?.height_max || product?.lines?.height_max;

            if (productHeight && productHeightMax) {
                return [...productHeight?.split(',').map((width) => width), ...productHeightMax?.split(',').map((width) => width)];
            }
            if (productHeight && !productHeightMax) {
                return [...productHeight?.split(',').map((width) => width)];
            }
            return [...productHeightMax?.split(',').map((width) => width)];
        }
    };

    const suggestedArticles = useMemo(() => {
        const doorType = product.lines.brands.type;
        const articleType = (() => {
            switch (product.lines.brands.name) {
                case 'Barański Premium': return doorType === 'Drzwi zewnętrzne' ? 'Premium zewnętrzne' : 'Premium wewnętrzne';
                case 'Barański Smart': return 'Smart';
                case 'Barański Optimo': return 'Optimo';
                default: return null;
            }
        })();
        const filteredArticles = articles.filter((a) => (a.subtype && a.subtype === articleType));
        return filteredArticles;
    }, [articles, product.lines.brands.type, product.lines.brands.name]);

    const selectBenefits = () => {
        if ((product?.lines?.benefits?.length && product?.benefits?.length) || product?.benefits?.length) {
            return product?.benefits?.map((advantage) => ({ desc: advantage.content }));
        }
        return product?.lines?.benefits?.length ? product?.lines?.benefits?.map((advantage) => ({ desc: advantage.content })) : [];
    };
    const benefits = selectBenefits();

    const propertiesDictionary = {
        // // //
        zalety:

        (benefits.length)
            ? (
                <div className="details__wrapper--mobile">
                    <Property headline="Zalety">
                        <ListView
                            data={ benefits }
                        />
                    </Property>
                </div>
            ) : null,

        // // //
        wykonczenia:

            (
                <div className="details__wrapper--desktop">
                    {
                        finishes.length && finishes?.map((finish) => {
                            if (finish?.name && finish?.image && finish?.colors.length) {
                                return (
                                    <Property
                                        isCollapsible={ false }
                                        headline={ (
                                            <>
                                                {
                                                    (finish?.image)
                                                        ? (<Img className="details__thumbnail" src={ `${process.env.GATSBY_URL}/${finish?.image}` } alt={ finish?.name } />)
                                                        : (null)
                                                }
                                                { finish?.name }
                                                {
                                                    (properties?.color?.name && properties?.finish === finish?.name)
                                                        && (`: ${properties?.color?.name}`)
                                                }
                                            </>
                                        ) }
                                    >
                                        <>
                                            <OptionsView
                                                isInteractive
                                                property="color"
                                                data={
                                                    finish?.colors
                                                }
                                                additionalData={{
                                                    finish: finish?.name
                                                }}
                                                activeOption={ properties?.color.id }
                                                activeAdditionalOption={ properties?.finish }
                                                setActiveOption={ setProperty }
                                            />
                                        </>
                                    </Property>
                                );
                            }
                            return null;
                        })
                    }
                </div>
            ),

        // // //
        'wykonczenia-mobile':

            (
                <div className="details__wrapper--mobile">
                    {
                        finishes
                            ? (
                                <Property headline="Rodzaj wykończenia">
                                    <Field
                                        name="Wybierz rodzaj wykończenia"
                                        type="select"
                                        value={ properties?.finish }
                                        filter="finish"
                                        options={ finishes }
                                        setProperty={ setProperty }
                                    />
                                </Property>
                            )
                            : (null)
                    }
                    {
                        properties?.finish && finishes && finishes?.filter((currentValue) => (currentValue?.name === properties?.finish)).map((finish) => (
                            <Property
                                headline={ (
                                    <>
                                        Kolor wykończenia
                                        {
                                            (properties?.color?.name && properties?.finish === finish?.name)
                                                && (`: ${properties?.color?.name}`)
                                        }
                                    </>
                                ) }
                            >
                                <>
                                    <OptionsView
                                        isInteractive
                                        property="color"
                                        data={
                                            finish?.colors
                                        }
                                        additionalData={{
                                            finish: finish?.name
                                        }}
                                        activeOption={ properties?.color.id }
                                        activeAdditionalOption={ properties?.finish }
                                        setActiveOption={ setProperty }
                                    />
                                </>
                            </Property>
                        ))
                    }
                </div>
            ),

        // // //
        kompletacja:

            (product?.lines?.door_completion)
                ? (
                    <Property headline="Kompletacja drzwi">
                        <Img className="details__picking" src={ picking } alt="kompletacja" />
                    </Property>
                )
                : (null),

        // // //
        'opcje-akustyczne':

            (product?.lines?.acoustics?.length)
                ? (
                    <Property headline="Opcje akustyczne">
                        <div className="details__acoustic">
                            {
                                (product?.lines?.acoustics?.filter(({ name }) => (name === 'W standardzie')))
                                    ? (
                                        <div className="acoustic__item">
                                            <Img className="acoustic__icon" src={ notes } alt="nuty" />
                                            <p>
                                                <strong className="acoustic__value">34 dB</strong>
                                                <br />
                                                w standardzie
                                            </p>
                                        </div>
                                    )
                                    : (null)
                            }
                            {
                                (product?.lines?.acoustics?.filter(({ name }) => (name === 'Za dopłatą')))
                                    ? (
                                        <div className="acoustic__item">
                                            <Img className="acoustic__icon" src={ notes } alt="nuty" />
                                            <p>
                                                <strong className="acoustic__value">44 dB</strong>
                                                <br />
                                                za dopłatą
                                            </p>
                                        </div>
                                    )
                                    : (null)
                            }
                        </div>
                    </Property>
                )
                : (null),

        // // // // standard_set_text_link
        'zestaw-standardowy':

            (product?.standard_set_list?.length || product?.lines?.standard_set_list?.length)
                ? (
                    <Property headline="Zestaw standardowy">
                        <ListView data={ product?.standard_set_list || product?.lines?.standard_set_list } />
                        {
                            ((product?.standard_set_text_link || product?.lines?.standard_set_text_link) && (product?.standard_set_text_name || product?.lines?.standard_set_text_name))
                                ? (<Link className="details__link" to={ product?.standard_set_text_link || product?.lines?.standard_set_text_link }>{ product?.standard_set_text_name || product?.lines?.standard_set_text_name }</Link>)
                                : (null)
                        }
                    </Property>
                )
                : (null),

        // // //
        oscieznice:

            (frames?.length)
                ? (
                    <Property headline="Ościeżnice">
                        <DoorFrames product={ product } frames={ frames } />
                    </Property>
                )
                : (null),

        // // //
        linia:

            (product?.lines?.description)
                ? (
                    <Property headline="Linia">
                        <p>{ product?.lines?.description }</p>
                    </Property>
                )
                : (null),

        // // // // glass_text_link
        szklenie:

            (product?.lines?.glass_text || product?.glass_text || product?.lines?.glass_colors?.length || product?.glass_colors?.length)
                ? (
                    <Property headline="Szklenie">
                        {
                            (product?.glass_text || product?.lines?.glass_text)
                                ? (<p style={{ marginBottom: '20px' }}>{ product?.glass_text || product?.lines?.glass_text }</p>)
                                : (null)
                        }
                        {
                            (product?.glass_colors?.length || product?.lines?.glass_colors?.length)
                                ? (<OptionsView isSliderOnly data={ product?.glass_colors?.length ? product?.glass_colors : product?.lines?.glass_colors } />)
                                : (null)
                        }
                        {
                            ((product?.glass_text_link || product?.lines?.glass_text_link) && (product?.glass_text_name || product?.lines?.glass_text_name))
                                ? (<Link className="details__link" to={ product?.glass_text_link || product?.lines?.glass_text_link }>{ product?.glass_text_name || product?.lines?.glass_text_name }</Link>)
                                : (null)
                        }
                    </Property>
                )
                : (null),

        // // // // stone_text_link
        kamien:

            (product.lines?.stone_text || product?.stone_text || product.lines?.stones?.length || product?.stones?.length)
                ? (
                    <Property headline="Kamień">
                        {
                            (product?.stone_text || product?.lines?.stone_text)
                                ? (<p style={{ marginBottom: '20px' }}>{ product?.stone_text || product?.lines?.stone_text }</p>)
                                : (null)
                        }
                        {
                            (product?.stones?.length || product.lines?.stones?.length)
                                ? (<OptionsView data={ product?.stones?.length ? product?.stones : product?.lines?.stones } />)
                                : (null)
                        }
                        {
                            ((product?.stone_text_link || product?.lines?.stone_text_link) && (product?.stone_text_name || product?.lines?.stone_text_name))
                                ? (<Link className="details__link" to={ product?.stone_text_link || product?.lines?.stone_text_link }>{ product?.stone_text_name || product?.lines?.stone_text_name }</Link>)
                                : (null)
                        }
                    </Property>
                )
                : (null),

        // // // // dimensions_text_link
        wymiary:

            (product?.lines?.width_standard || product?.lines?.width_max || product?.lines?.height_standard || product?.lines?.height_max || product?.width_standard || product?.width_max || product?.height_standard || product?.height_max)
                ? (
                    <Property headline="Wymiary drzwi jednoskrzydłowych">
                        {
                            (product?.lines?.width_standard || product?.lines?.width_max || product?.width_standard || product?.width_max)
                                ? (
                                    <>
                                        <p className="details__subheadline--size">
                                            <strong>Szerokość</strong>
                                            { ' ' }
                                            w świetle przejścia [cm]
                                        </p>
                                        <div className="details__sizing">
                                            {
                                                doorDimensions.widths().map((doorWidth) => <div className="size">{doorWidth}</div>)
                                            }
                                        </div>
                                    </>
                                )
                                : (null)
                        }
                        {
                            (product?.lines?.height_standard || product?.lines?.height_max || product?.height_standard || product?.height_max)
                                ? (
                                    <>
                                        <p className="details__subheadline--size">
                                            <strong>Wysokość</strong>
                                            { ' ' }
                                            w świetle przejścia [cm]
                                        </p>
                                        <div className="details__sizing">
                                            {
                                                doorDimensions.heights().map((doorHeight) => <div className="size">{doorHeight}</div>)
                                            }
                                        </div>
                                    </>
                                )
                                : (null)
                        }
                        {
                            ((product?.dimensions_text_link || product?.lines?.dimensions_text_link) && (product?.dimensions_text_name || product?.lines?.dimensions_text_name))
                                ? (<Link className="details__link" to={ product?.dimensions_text_link || product?.lines?.dimensions_text_link }>{ product?.dimensions_text_name || product?.lines?.dimensions_text_name }</Link>)
                                : (null)
                        }
                    </Property>
                )
                : (null),

        // // // // addons_text_link
        'naswietla-i-dostawki':

            (product?.lines?.addons_text || product?.lines?.addons?.length || product?.addons_text || product?.addons?.length)
                ? (
                    <Property headline="Naświetla i dostawki">
                        {
                            (product?.addons_text || product?.lines?.addons_text)
                                ? (<p style={{ marginBottom: '20px' }}>{ product?.addons_text || product?.lines?.addons_text }</p>)
                                : (null)
                        }
                        {
                            (product?.addons?.length || product?.lines?.addons?.length)
                                ? (<OptionsView data={ product?.addons?.length ? product?.addons : product?.lines?.addons } />)
                                : (null)
                        }
                        {
                            ((product?.addons_text_link || product?.lines?.addons_text_link) && (product?.addons_text_name || product?.lines?.addons_text_name))
                                ? (<Link className="details__link" to={ product?.addons_text_link || product?.lines?.addons_text_link }>{ product?.addons_text_name || product?.lines?.addons_text_name }</Link>)
                                : (null)
                        }
                    </Property>
                )
                : (null),

        // // // // other_options_text_link
        klamki:
            (product?.accessories?.length || product?.lines?.accessories?.length)
                ? (
                    <Property headline="Klamki">
                        <>
                            {
                                (product?.other_options_text || product?.lines?.other_options_text)
                                    ? (<p style={{ marginBottom: '20px' }}>{ product?.other_options_text || product?.lines?.other_options_text }</p>)
                                    : (null)
                            }
                            <OptionsView isAccessory isSliderOnly data={ product?.accessories?.length ? product?.accessories : product?.lines?.accessories } />
                        </>
                        {
                            ((product?.other_options_text_link || product?.lines?.other_options_text_link) && (product?.other_options_text_name || product?.lines?.other_options_text_name))
                                ? (<Link className="details__link" to={ product?.other_options_text_link || product?.lines?.other_options_text_link }>{ product?.other_options_text_name || product?.lines?.other_options_text_name }</Link>)
                                : (null)
                        }
                    </Property>
                )
                : (null),

        // // // // accessories_text_link
        akcesoria:

            (product?.accessories_text || product?.lines?.accessories_text)
                ? (
                    <Property headline="Akcesoria">
                        <p>{ product?.accessories_text || product?.lines?.accessories_text }</p>
                        {
                            ((product?.accessories_text_link || product?.lines?.accessories_text_link) && (product?.accessories_text_name || product?.lines?.accessories_text_name))
                                ? (<Link className="details__link" to={ product?.accessories_text_link || product?.lines?.accessories_text_link }>{ product?.accessories_text_name || product?.lines?.accessories_text_name }</Link>)
                                : (null)
                        }
                    </Property>
                )
                : (null),

        seo:
            (product?.seo_description)
                ? (
                    <Property headline="Opis">
                        {/* eslint-disable-next-line react/no-danger */}
                        <div dangerouslySetInnerHTML={{ __html: product.seo_description }} />
                    </Property>
                )
                : (null),
        articles:
            (suggestedArticles.length > 0) ? (
                <Property headline="Dowiedz się więcej">
                    <Swiper
                        slidesPerView={ 1 }
                        navigation={{
                            nextEl: '.articleCarousel--next',
                            prevEl: '.articleCarousel--previous'
                        }}
                        loop
                        loopedSlides={ suggestedArticles.length }
                        centerInsufficientSlides
                        spaceBetween={ 15 }
                        allowTouchMove
                    >
                        {suggestedArticles.map((suggestedArticle) => (
                            <SwiperSlide key={ suggestedArticle.slug }>
                                <Link to={ `${routes.artykul.path}/${suggestedArticle.slug}` }>
                                    <PostView post={ suggestedArticle } />
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <ButtonsView name="articleCarousel" />
                </Property>
            )
                : (null)
    };

    // define properties list for each door type separately
    const drzwiWewnetrzneProperties = [
        propertiesDictionary['wykonczenia'],
        propertiesDictionary['wykonczenia-mobile'],
        propertiesDictionary['zalety'],
        propertiesDictionary['kompletacja'],
        propertiesDictionary['opcje-akustyczne'],
        propertiesDictionary['zestaw-standardowy'],
        propertiesDictionary['oscieznice'],
        propertiesDictionary['linia'],
        propertiesDictionary['szklenie'],
        propertiesDictionary['kamien'],
        propertiesDictionary['wymiary'],
        propertiesDictionary['klamki'],
        propertiesDictionary['seo'],
        propertiesDictionary['articles']
    ];
    const drzwiZewnetrzneProperties = [
        propertiesDictionary['wykonczenia'],
        propertiesDictionary['wykonczenia-mobile'],
        propertiesDictionary['zalety'],
        propertiesDictionary['opcje-akustyczne'],
        propertiesDictionary['zestaw-standardowy'],
        propertiesDictionary['oscieznice'],
        propertiesDictionary['linia'],
        propertiesDictionary['szklenie'],
        propertiesDictionary['kamien'],
        propertiesDictionary['wymiary'],
        propertiesDictionary['klamki'],
        propertiesDictionary['seo'],
        propertiesDictionary['articles']
    ];
    const drzwiKlatkoweITechniczneProperties = [
        propertiesDictionary['wykonczenia'],
        propertiesDictionary['wykonczenia-mobile'],
        propertiesDictionary['zalety'],
        propertiesDictionary['opcje-akustyczne'],
        propertiesDictionary['zestaw-standardowy'],
        propertiesDictionary['oscieznice'],
        propertiesDictionary['linia'],
        propertiesDictionary['szklenie'],
        propertiesDictionary['kamien'],
        propertiesDictionary['wymiary'],
        propertiesDictionary['klamki'],
        propertiesDictionary['seo'],
        propertiesDictionary['articles']
    ];

    const propertiesView = () => {
        const doorType = product.lines.brands.type;

        switch (doorType) {
            case doorTypes.inner.name:
                return drzwiWewnetrzneProperties;

            case doorTypes.outer.name:
                return drzwiZewnetrzneProperties;

            case doorTypes.technical.name:
                return drzwiKlatkoweITechniczneProperties;

            default:
                return null;
        }
    };

    return (
        <Details product={ product } properties={ properties }>
            { propertiesView() }
        </Details>
    );
};

DetailsView.propTypes = {
    frames: PropTypes.instanceOf(Array).isRequired,
    product: PropTypes.instanceOf(Object).isRequired,
    finishes: PropTypes.instanceOf(Array).isRequired,
    articles: PropTypes.instanceOf(Array).isRequired,
    properties: PropTypes.instanceOf(Object).isRequired,
    setProperty: PropTypes.func.isRequired
};