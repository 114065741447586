import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { FieldView } from './view';

export const Field = ({
    name,
    type,
    placeholder,
    text,
    logo,
    value,
    filter,
    properties,
    options,
    setValue,
    setFilter,
    setProperty,
    extendedOptionsInformation,
    isBestseller,
    isFavorite
}) => {
    const [isVisible, setVisibility] = useState(false);

    const toggleVisibility = () => {
        setVisibility((previousVisibility) => (!previousVisibility));
    };

    useEffect(() => {
        setVisibility(false);
    }, [value]);

    return (
        <FieldView
            name={ name }
            type={ type }
            placeholder={ placeholder }
            text={ text }
            logo={ logo }
            value={ value }
            filter={ filter }
            properties={ properties }
            options={ options }
            isVisible={ isVisible }
            setValue={ setValue }
            setVisibility={ setVisibility }
            setFilter={ setFilter }
            setProperty={ setProperty }
            toggleVisibility={ toggleVisibility }
            extendedOptionsInformation={ extendedOptionsInformation }
            isBestseller={ isBestseller }
            isFavorite={ isFavorite }
        />
    );
};

Field.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    text: PropTypes.string,
    logo: PropTypes.string,
    value: PropTypes.string,
    filter: PropTypes.string,
    properties: PropTypes.instanceOf(Set),
    options: PropTypes.instanceOf(Array) || PropTypes.instanceOf(Object),
    setValue: PropTypes.func,
    setFilter: PropTypes.func,
    setProperty: PropTypes.func,
    extendedOptionsInformation: PropTypes.bool,
    isBestseller: PropTypes.bool,
    isFavorite: PropTypes.bool
};

Field.defaultProps = {
    placeholder: '',
    text: '',
    logo: '',
    value: '',
    filter: '',
    properties: new Set(),
    options: [],
    setValue: () => {},
    setFilter: () => {},
    setProperty: () => {},
    extendedOptionsInformation: false,
    isBestseller: false,
    isFavorite: false
};