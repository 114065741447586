/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import './index.scss';

import React, { useState, useEffect, useRef } from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

SwiperCore.use([Pagination]);

const DoorFrames = ({ product, frames }) => {
    const [productLine] = useState(() => product?.lines?.name);
    const [productBrand] = useState(() => product?.lines?.brands?.name);
    const [lineDoorFrames] = useState(() => frames.filter((frame) => (frame.brandName === productBrand && frame.lineName === productLine)));
    const [doorDoorFrames] = useState(() => {
        const doorFrames = product?.frame_list;
        doorFrames.map((frame) => {
            const parsedTable = JSON.parse(frame.table);
            return frame.table = parsedTable;
        });
        return doorFrames;
    });
    const [tableList, setTableList] = useState([]);

    const [currentSlide, setCurrentSlide] = useState(0);

    const ref = useRef();
    const [isIntersecting] = useState(true);

    // observing if the slider is visible on the screen. If not - don't render the slides.
    // This is only needed in case of making door frames option collapsible
    // useEffect(() => {
    //     const observer = new IntersectionObserver(
    //         ([entry]) => setIntersecting(entry.isIntersecting)
    //     );

    //     observer.observe(ref.current);
    //     return () => { observer.disconnect(); };
    // }, [ref.current]);

    const groupedLineTables = {};
    const groupedDoorTables = {};

    // Preparing object for the table generation (takem from Lines)
    lineDoorFrames[0]?.tabels.forEach((table) => table?.rows.forEach((row) => {
        if (table?.frameTypeName in groupedLineTables) {
            if (row?.column1 in groupedLineTables[table?.frameTypeName].rows) {
                groupedLineTables[table?.frameTypeName].rows[row?.column1] = [...groupedLineTables[table?.frameTypeName].rows[row?.column1], [row?.column2, row?.column3]];
            } else {
                groupedLineTables[table?.frameTypeName].rows[row?.column1] = [[row?.column2, row?.column3]];
            }
        } else {
            groupedLineTables[table?.frameTypeName] = {};
            groupedLineTables[table?.frameTypeName].rows = {};
            groupedLineTables[table?.frameTypeName].columns = {};
            groupedLineTables[table?.frameTypeName].columns = table.columns;
            groupedLineTables[table?.frameTypeName].rows[row?.column1] = [[row?.column2, row?.column3]];
        }
    }));
    // Preparing object for the table generation (takem from Door)
    doorDoorFrames?.forEach((table) => {
        groupedDoorTables[table?.name] = {};
        groupedDoorTables[table?.name].rows = {};
        groupedDoorTables[table?.name].columns = {};
        groupedDoorTables[table?.name].columns.column1 = table?.column1;
        groupedDoorTables[table?.name].columns.column2 = table?.column2;
        groupedDoorTables[table?.name].columns.column3 = table?.column3;

        table?.table?.forEach((row) => {
            if (table?.name in groupedDoorTables) {
                if (row?.column1 in groupedDoorTables[table?.name]?.rows) {
                    groupedDoorTables[table?.name].rows[row?.column1] = [...groupedDoorTables[table?.name]?.rows[row?.column1], [row?.column2, row?.column3]];
                } else {
                    groupedDoorTables[table?.name].rows[row?.column1] = [[row?.column2, row?.column3]];
                }
            } else {
                groupedDoorTables[table?.name] = {};
                groupedDoorTables[table?.name].rows = {};
                groupedDoorTables[table?.name].columns = {};
                groupedDoorTables[table?.name].columns = table?.columns;
                groupedDoorTables[table?.name].rows[row?.column1] = [[row?.column2, row?.column3]];
            }
        });
    });

    // creates markdown from each table in the groupedLineTables
    const doorTableMarkdownArray = Object.keys(groupedDoorTables).map((frameName) => (
        <table>
            <thead>
                <tr>
                    <th className="table-name" colSpan="3">{frameName}</th>
                </tr>
                <tr className="table-columns">
                    <th>{groupedDoorTables[frameName]?.columns?.column1}</th>
                    <th>{groupedDoorTables[frameName]?.columns?.column2}</th>
                    <th>{groupedDoorTables[frameName]?.columns?.column3}</th>
                </tr>
            </thead>
            <tbody>
                {
                    Object.keys(groupedDoorTables[frameName].rows).map((rowDescription, rowIndex) => groupedDoorTables[frameName].rows[rowDescription].map((row, idx, rowDescriptionArray) => {
                        const isRowIndexEven = rowIndex % 2 === 0;
                        const rowClassName = classNames({ odd: !isRowIndexEven }, { even: isRowIndexEven });

                        if (rowDescriptionArray.length > 1) {
                            if (idx === 0) {
                                return (
                                    <tr className={ rowClassName }>
                                        <td
                                            rowSpan={ rowDescriptionArray.length }
                                        >
                                            {rowDescription}
                                        </td>
                                        <td>{row[0]}</td>
                                        <td>{row[1]}</td>
                                    </tr>
                                );
                            }
                            return (
                                <tr className={ rowClassName }>
                                    <td>{row[0]}</td>
                                    <td>{row[1]}</td>
                                </tr>
                            );
                        }
                        return (
                            <tr className={ rowClassName }>
                                <td>
                                    {rowDescription}
                                </td>
                                <td>{row[0]}</td>
                                <td>{row[1]}</td>
                            </tr>
                        );
                    }))
                }
            </tbody>
        </table>
    ));

    // creates markdown from each table in the groupedLineTables
    const lineTableMarkdownArray = Object.keys(groupedLineTables).map((frameName) => (
        <table>
            <thead>
                <tr>
                    <th className="table-name" colSpan="3">{frameName}</th>
                </tr>
                <tr className="table-columns">
                    <th>{groupedLineTables[frameName]?.columns?.column1}</th>
                    <th>{groupedLineTables[frameName]?.columns?.column2}</th>
                    <th>{groupedLineTables[frameName]?.columns?.column3}</th>
                </tr>
            </thead>
            <tbody>
                {
                    Object.keys(groupedLineTables[frameName].rows).map((rowDescription, rowIndex) => groupedLineTables[frameName].rows[rowDescription].map((row, idx, rowDescriptionArray) => {
                        const isRowIndexEven = rowIndex % 2 === 0;
                        const rowClassName = classNames({ odd: !isRowIndexEven }, { even: isRowIndexEven });

                        if (rowDescriptionArray.length > 1) {
                            if (idx === 0) {
                                return (
                                    <tr className={ rowClassName }>
                                        <td
                                            rowSpan={ rowDescriptionArray.length }
                                        >
                                            {rowDescription}
                                        </td>
                                        <td>{row[0]}</td>
                                        <td>{row[1]}</td>
                                    </tr>
                                );
                            }
                            return (
                                <tr className={ rowClassName }>
                                    <td>{row[0]}</td>
                                    <td>{row[1]}</td>
                                </tr>
                            );
                        }
                        return (
                            <tr className={ rowClassName }>
                                <td>
                                    {rowDescription}
                                </td>
                                <td>{row[0]}</td>
                                <td>{row[1]}</td>
                            </tr>
                        );
                    }))
                }
            </tbody>
        </table>
    ));

    useEffect(() => {
        // sets tables markdown as an array to be used in Swiper
        setTableList(doorTableMarkdownArray.length ? doorTableMarkdownArray : lineTableMarkdownArray);
    }, []);

    return (
        <>
            <Swiper
                ref={ ref }
                spaceBetween={ 25 }
                slidesPerView="auto"
                centeredSlides
                // if there is only one element in tables array - don't show the pagination buttons
                pagination={ lineDoorFrames[0]?.tabels.length > 1 ? {
                    clickable: true
                } : false }
                modules={ [Pagination] }
                className={ classNames(
                    'carousel__frames-container',
                    { 'shadow-left': currentSlide === tableList.length - 1 },
                    { 'shadow-left-right': currentSlide > 0 && currentSlide < tableList.length - 1 },
                    { 'shadow-right': currentSlide === 0 }
                ) }
                onSlideChange={ (swiperCore) => setCurrentSlide(swiperCore.realIndex) }
            >
                {
                    // if element is not intersecting the view - don't render slides
                    // if tables array is empty - display fallback text
                    isIntersecting && (tableList.length ? tableList.map((table, index) => (
                        <SwiperSlide className="frames-container__slide" key={ index }>
                            { table }
                        </SwiperSlide>
                    )) : <SwiperSlide style={{ display: 'flex' }}>{[<span style={{ width: '100%', textAlign: 'center' }}>Brak&nbsp;wyników</span>]}</SwiperSlide>)
                }
            </Swiper>
        </>
    );
};

DoorFrames.propTypes = {
    frames: PropTypes.instanceOf(Array).isRequired,
    product: PropTypes.instanceOf(Object).isRequired
};

export default DoorFrames;