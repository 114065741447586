import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import { DoorsView } from './view';

export const Doors = ({ line }) => {
    const doors = useStaticQuery(graphql`
        query {
            allDoors(filter: { is_active: { eq: 1 } }) {
                nodes {
                    alternative_id
                    lines {
                        brands {
                            name
                            type
                        }
                        name
                        alternative_id
                    }
                    variants {
                        main
                        main_model
                    }
                    custom_variants {
                        main
                        main_model
                    }
                    name
                    price
                    bestseller
                }
            }
        }
    `);
    const similarDoors = doors?.allDoors?.nodes?.filter(
        (currentValue) => currentValue?.lines?.alternative_id === line?.alternative_id
    );
    if (similarDoors.length < 4) return null;
    return (
        <DoorsView
            doors={ doors?.allDoors?.nodes?.filter(
                (currentValue) => currentValue?.lines?.alternative_id === line?.alternative_id
            ) }
        />
    );
};

Doors.propTypes = {
    line: PropTypes.instanceOf(Object).isRequired
};
