/* eslint-disable prefer-destructuring */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DetailsView } from './view';
import Cookies from 'universal-cookie';

export const Details = ({ data, frames, articles }) => {
    const getFinishes = (variants) => variants.reduce((acc, variant) => {
        const name = variant.line_variant.name;
        if (!acc[name]) {
            return {
                ...acc,
                [name]: {
                    image: variant.line_variant.trim.image, name, colors: [{ ...variant.color, main: variant.main }]
                }
            };
        }
        return { ...acc, [name]: { ...acc[name], colors: [...acc[name].colors, { ...variant.color, main: variant.main }] } };
    }, { });
    const customFinishes = Object.values(getFinishes(data?.custom_variants));
    const finishes = Object.values(getFinishes(data?.variants)).map((finish) => {
        const customFinish = customFinishes.find((c) => c.name === finish.name);
        if (!customFinish) return finish;
        return { ...finish, colors: finish.colors.concat(customFinish.colors) };
    });

    const cookies = new Cookies();

    let avaliableFinishes = finishes;
    let avaliableFinish;
    let avaliableColor;

    if (cookies.get('finishes')) {
        avaliableFinishes = finishes.filter((finish) => (cookies.get('finishes').split(',').includes(finish.name)));
        cookies.remove('finishes', { path: '/' });
    }

    if (cookies.get('colors')) {
        avaliableFinish = avaliableFinishes.filter((finish) => (finish.colors.filter((color) => (cookies.get('colors').split(',').includes(color?.color_filter_name))).length))[0];
        avaliableColor = avaliableFinish?.colors.find((color) => (cookies.get('colors').split(',').includes(color?.color_filter_name)));
        cookies.remove('colors', { path: '/' });
    }

    const getInitialFinish = () => {
        const mainFinish = finishes.find((f) => f.colors.find((c) => c.main));
        if (!mainFinish) return '';
        return mainFinish.name;
    };

    const getInitialColor = () => {
        const finishColors = finishes.map((f) => f.colors).flat();
        const mainFinish = finishColors.find((finish) => finish.main);
        if (!mainFinish) return '';
        return {
            id: mainFinish.alternative_id,
            name: mainFinish.name
        };
    };

    const initialProperties = {
        finish: avaliableFinish ? avaliableFinish.name : getInitialFinish(),
        color: avaliableColor ? {
            id: avaliableColor.alternative_id,
            name: avaliableColor.name
        } : getInitialColor()
    };

    const [properties, setProperties] = useState(initialProperties);
    const setProperty = (currentProperties) => {
        setProperties((previousProperties) => (
            Object.values(previousProperties).includes(Object.values(currentProperties)[0])
                ? initialProperties
                : {
                    ...previousProperties,
                    ...currentProperties
                }
        ));
    };

    return (
        <>
            <DetailsView
                product={ data }
                frames={ frames }
                finishes={ finishes }
                properties={ properties }
                setProperty={ setProperty }
                articles={ articles }
            />
        </>
    );
};

Details.propTypes = {
    frames: PropTypes.instanceOf(Array).isRequired,
    articles: PropTypes.instanceOf(Array).isRequired,
    data: PropTypes.string.isRequired
};